<template>
  <v-dialog
    v-model="showForm"
    max-width="800px"
  >
    <v-card v-if="editedItem">
      <v-card-title>
        <span class="headline">{{ formTitle }}</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col
              cols="12"
              sm="12"
              md="6"
            >
              <v-text-field
                v-bind="{ 'outlined' : true}"
                v-model="editedItem.Name"
                :label="$t('sysUser.name', locale)"
                hide-details
              />
            </v-col>
            <v-col
              cols="12"
              sm="12"
              md="6"
            >
              <v-select
                v-model="editedItem.Type"
                :items="types"
                :label="$t('workspace.contentType.type', locale)"
                :disabled="editedItem.ID !== null"
                item-text="name"
                item-value="value"
                outlined
                hide-details
              />
            </v-col>
          </v-row>
          <v-row
            v-if="isWithSize"
          >
            <v-col
              cols="12"
              sm="6"
              md="6"
            >
              <v-text-field
                v-bind="{ 'outlined' : true}"
                v-model="editedItem.Width"
                :label="$t('workspace.contentType.width', locale)"
                hide-details
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="6"
            >
              <v-text-field
                v-bind="{ 'outlined' : true}"
                v-model="editedItem.Height"
                :label="$t('workspace.contentType.height', locale)"
                hide-details
              />
            </v-col>
          </v-row>
          <v-row
            v-if="isContainsImage"
          >
            <v-col
              cols="12"
              sm="12"
              md="12"
            >
              <v-select
                v-model="editedItem.AllowedTypes"
                :items="allowedTypes"
                :label="$t('workspace.contentType.allowedTypes', locale)"
                multiple
                chips
              />
            </v-col>
          </v-row>
          <v-row
            v-if="editedItem.Type === 'audio'"
          >
            <v-col
              cols="12"
              sm="12"
              md="12"
            >
              <v-switch
                v-model="editedItem.BySequence"
                :label="$t('workspace.contentType.bySequence', locale)"
                color="primary"
                hide-details
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <action-buttons
        :id="`${editedItem.ID}${editedItem.tmpNewItemID}`"
        :onDeleteItem="onDelete"
        :onSaveItem="handleSave"
        :onClose="handleClose"
        :locale="locale"
      />
    </v-card>
  </v-dialog>
</template>

<script>
  import { mapState } from 'vuex'
  import constants from '@/constants'
  import ActionButtons from '@/components/ActionButtons'
  export default {
    name: 'ContentTypeForm',
    components: {
      ActionButtons,
    },
    props: {
      data: {
        type: Object,
        default: null
      },
      showForm: {
        type: Boolean,
        default: false
      },
      onChangeContentType: {
        type: Function,
        required: true
      },
      onClose: {
        type: Function,
        required: true
      },
      onDelete: {
        type: Function,
        required: true
      },
    },
    data: () => ({
      formTitle: '',
      editedItem: null,
      isWithSize: false,
      isContainsImage: false,
    }),
    computed: {
      ...mapState('app',['locale']),
      types () {
        return constants.dviewcontentTypes
      },
      allowedTypes () {
        return constants.dviewcontentAllowedTypes
      },
    },
    watch: {
      'editedItem.Type' (v) {
        const aux = constants.dviewcontentTypes.filter(x => x.value===v)
        this.isWithSize = aux.length > 0 && aux[0].withSize === true
        this.isContainsImage = aux.length > 0 && aux[0].containsImage === true
      },
      data (v) {
        this.editedItem = v
      },
    },
    methods: {
      handleSave() {
        this.onChangeContentType(this.editedItem)
      },
      handleClose () {
        this.onClose()
      },
    },
  }
</script>
